export const formatAddress = (address) => {
  const addressParts = address.state
    ? [
        address.city,
        address.state.split("-").pop(),
        address.countryNormalized,
      ].filter(Boolean)
    : [address.city, address.countryNormalized].filter(Boolean)
  return addressParts.join(", ")
}
